<template>
  <div>
    <header-menu ref="HeaderMenu"></header-menu>
    <div class="view-content">
      <div class="banner"></div>
      <div class="l_r_space t__space">
        <h2 class="nav_title font-size_40">{{ newsInfo.title }}</h2>
        <p style="color: #999" class="font-size_24">
          {{ newsInfo._date }}
        </p>
        <div v-html="newsInfo.content_text" style="text-align: left"></div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import { newsRow } from "@/api";
import moment from "moment";
import Footer from "@/components/Footer";
import HeaderMenu from "@/components/HeaderMenu";
import news_content_1 from "@/assets/img/png/news_content_1.png";
export default {
  name: "newsContent",
  components: { Footer, HeaderMenu },
  beforeCreate() {
    this.news_id = this.$route.params.news_id;
  },

  data() {
    return {
      newsInfo: {},
    };
  },
  created() {
    this.getNewsRow();
  },
  methods: {
    moment,
    getNewsRow() {
      let parameter = { news_id: this.news_id };
      newsRow(parameter).then((res) => {
        if (res.status === 0) {
          let data = res.data;
          data._date = moment(data.create_date).format("YYYY-MM-DD");
          this.newsInfo = data;
          document.title = '正也科技S2P官网-新闻资讯-'+this.newsInfo.title;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.nav_title {
  margin-bottom: 0;
}
.news__content {
  .content_photo {
    height: 9.22rem;
    min-height: 100px;
    // background: url("~@/assets/img/png/news_content_1.png");
    background-size: 100% 100%;
    margin-bottom: 0.34rem;
  }
}
.banner {
  width: 100%;
  position: relative;
  background: url("~@/assets/img/png/news_content_banner.png");
  background-size: 100% 100%;
  height: 4.6rem;
}
</style>
